"use client";

import LoadingSpinner from "@/components/ui/custom/LoadingSpinner";

const Loading = () => {
  return (
    <div className="w-full h-screen">
      <LoadingSpinner />
    </div>
  );
};

export default Loading;
